import React from 'react';
import './Footer.css'

class Footer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            activityYears: this.getCopyrightYear()
        }
    }

    getCopyrightYear() {
        const currentYear = new Date().getFullYear()
        switch (currentYear) {
            case CREATION_YEAR: return "2022"
            default: return `2022-${currentYear}`
        }
    }

    render() {
        return (
            <footer className="Content-block">
                <p>&copy; {this.state.activityYears} Artem &#10084; Tanya </p>
            </footer>
        );
    }
}

const CREATION_YEAR = 2022;

export default Footer