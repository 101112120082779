import React from 'react';
import './Welcome.css'
import {apiCall} from "../App";

class Welcome extends React.Component {
    resolveWelcomeType(guests) {
        if (Array.isArray(guests)) {
            switch (guests.length) {
                case 1:
                    return WelcomeType.SINGLE;
                case 2:
                    return WelcomeType.COUPLE;
                default:
                    return WelcomeType.ANON;
            }
        }
        return WelcomeType.ANON
    }

    render() {
        const guests = this.props.guests;
        const welcomeType = this.resolveWelcomeType(guests);
        let personalWelcomeMsg;
        switch (welcomeType) {
            case WelcomeType.SINGLE:
                personalWelcomeMsg =
                    <h2>Дорог{guests[0].sex === Sex.MALE ? "ой" : "ая"} <b>{guests[0].user_name}</b><br/>приглашаем вас
                        на
                        нашу свадьбу</h2>
                break;
            case WelcomeType.COUPLE:
                personalWelcomeMsg =
                    <h2>Дорогие <b>{guests[0].user_name}</b> и <b>{guests[1].user_name}</b><br/>приглашаем вас на нашу
                        свадьбу</h2>
                break;
            case WelcomeType.ANON:
            default:
                personalWelcomeMsg = <h2>Приглашаем вас на нашу свадьбу</h2>
        }
        return (
            <div className="Content-block Content-block-text Welcome">
                {personalWelcomeMsg}
            </div>
        );
    }
}

export class ConfirmScreen extends React.Component {
    constructor(props) {
        super(props)
        this.confirm = this.confirm.bind(this)

        this.state = {
            confirmAction: getConfirmationAction(props.guests)
        }

        this.callInProgress = false
    }

    updateConfirmAction(action) {
        updateConfirmAction(this, action)
    }

    confirm(value = 1) {
        if (this.callInProgress) return

        this.callInProgress = true
        callConfirmApi(this, value).then((action) => {
            this.props.onCofirmActionChange(action)
        }).finally(() => {
            this.callInProgress = false
        })
    }

    render() {
        let confirmBlock, extraClassName

        switch (this.state.confirmAction) {
            case ConfirmAction.ASK_TO_ACCEPT:
                confirmBlock = (
                    <h4>Не забудьте&nbsp;
                        <a onClick={() => this.confirm(1)}
                           className="ConfirmScreen-accept-button ConfirmScreen-button">подтвердить</a>
                        &nbsp;свое присутствие
                    </h4>
                )
                extraClassName = "ConfirmScreen-accept ConfirmScreen-appearing"
                break;
            case ConfirmAction.ALLOW_TO_REJECT:
                confirmBlock = (
                    <h4>Вы подтвердили свое присутствие на мероприятии,&nbsp;
                        <a onClick={() => this.confirm(0)}
                           className="ConfirmScreen-reject-button ConfirmScreen-button">отказаться</a>
                    </h4>
                )
                extraClassName = "ConfirmScreen-reject ConfirmScreen-appearing"
                break;
            case ConfirmAction.NONE:
            default:
                confirmBlock = []
                extraClassName = "ConfirmScreen-none"
        }
        return (
            <div className={`Content-block Content-block-text ConfirmScreen ${extraClassName}`}>
                {confirmBlock}
            </div>
        );
    }
}

export function getConfirmationAction(guests) {
    let isInvitationConfirmed = false
    let isFirstVisit = true
    for (const guest of guests) {
        if (guest.is_confirmed === "1") {
            isInvitationConfirmed = true
            break
        }
        if (guest.visit_count !== "0") {
            isFirstVisit = false
        }
    }
    let confirmAction = ConfirmAction.NONE
    if (isInvitationConfirmed) {
        confirmAction = ConfirmAction.ALLOW_TO_REJECT
    } else if (!isFirstVisit) {
        confirmAction = ConfirmAction.ASK_TO_ACCEPT
    }
    return confirmAction
}

export function updateConfirmAction(stateOwner, action) {
    if (!Object.values(ConfirmAction).includes(action)) {
        throw new Error(`unknown ConfirmAction type ${action}`)
    }
    stateOwner.setState({
        confirmAction: action
    })
}

export function callConfirmApi(stateOwner, value = 1) {
    const me = stateOwner
    return apiCall(
        "confirm",
        {...me.props.invite, confirm: value},
        (data) => {
            let result = ConfirmAction.NONE
            if (data && data.success) {
                switch (value) {
                    case 0:
                        console.log("invitation has been unconfirmed");
                        me.setState({confirmAction: ConfirmAction.ASK_TO_ACCEPT})
                        result = ConfirmAction.ASK_TO_ACCEPT
                        break
                    case 1:
                    default:
                        console.log("invitation has been confirmed");
                        me.setState({confirmAction: ConfirmAction.ALLOW_TO_REJECT})
                        result = ConfirmAction.ALLOW_TO_REJECT
                }
            } else {
                let msg = "Unknown"
                if (data && data.error) {
                    msg = data.error
                }
                throw new Error(msg)
            }
            return result
        },
        (e) => {
            switch (value) {
                case 0:
                    console.log("invitation hasn't been unconfirmed", e);
                    break
                case 1:
                default:
                    console.log("invitation hasn't been confirmed", e);
            }
        }
    )
}

export const ConfirmAction = {
    ASK_TO_ACCEPT: "ask-to-accept",
    ALLOW_TO_REJECT: "ask-to-reject",
    NONE: "none"
}

const WelcomeType = {
    SINGLE: "single",
    COUPLE: "couple",
    ANON: "anon"
}

const Sex = {
    MALE: "m",
    FEMALE: "f"
}

export default Welcome