import React from 'react';
import "./Content.css"
import Welcome, {ConfirmScreen} from "./Welcome";
import When from "./When";
import Where from "./Where";
import DressCode from "./DressCode";
import PresentCode from "./PresentCode";
import WhereToStay, {CONTACT_PHONE} from "./WhereToStay";
import Credits from "./Credits";

class Content extends React.Component {
    constructor(props) {
        super(props);

        this.confirmScreenRef = null
        this.creditsRef = null

        this.updateConfirmAction = this.updateConfirmAction.bind(this)
    }

    updateConfirmAction(component, newConfirmAction) {
        component.updateConfirmAction(newConfirmAction)
    }

    render() {
        return (
            <div className="Content">
                <CallPhone/>
                <SendEmail/>
                <Welcome guests={this.props.guests}/>
                <ConfirmScreen
                    ref={x => this.confirmScreenRef = x}
                    guests={this.props.guests}
                    invite={this.props.invite}
                    onCofirmActionChange={(action) => this.updateConfirmAction(this.creditsRef, action)}
                />
                <hr/>
                <When guests={this.props.guests} invite={this.props.invite} anon={this.props.anon}/>
                <Where/>
                <DressCode/>
                <PresentCode/>
                <hr/>
                <WhereToStay/>
                <Credits
                    ref={x => this.creditsRef = x}
                    guests={this.props.guests}
                    invite={this.props.invite}
                    anon={this.props.anon}
                    onCofirmActionChange={(action) => this.updateConfirmAction(this.confirmScreenRef, action)}
                />
            </div>
        );
    }
}

export class SendEmail extends React.Component {
    constructor(props) {
        super(props);
        this.sendEmail = this.sendEmail.bind(this);
    }

    sendEmail() {
        const subject = encodeURIComponent("Вопрос, идея по свадьбе Артема и Тани")
        const body = encodeURIComponent("Доброго временни суток, у меня вопрос...")
        window.open(`mailto:${CONTACT_EMAIL}?subject=${subject}&body=${body}`)
    }

    render() {
        return (
            <div id="send-email-container">
                <div className="contact-button" id="send-email-button" onClick={this.sendEmail}/>
            </div>
        )
    }
}

export class CallPhone extends React.Component {
    constructor(props) {
        super(props);
        this.callPhone = this.callPhone.bind(this);
    }

    callPhone() {
        window.open(`tel:${CONTACT_PHONE}`)
    }

    render() {
        return (
            <div id="call-phone-container">
                <div className="contact-button" id="call-phone-button" onClick={this.callPhone}/>
            </div>
        )
    }
}

const CONTACT_EMAIL = "wedding@artemlovestanya.ru"

export default Content