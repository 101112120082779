import React from 'react';
import './App.css';
import Poster from './components/Poster'
import Content from './components/Content'
import Footer from './components/Footer'

class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoadingFinished: false,
            isDataFetched: false,
            guests: [
                {
                    user_id: 0,
                    user_name: "Гость",
                    sex: 'm',
                    visit_count: "0",
                    is_confirmed: "0",
                    meta_data: null
                }
            ],
            anon: false,
            invite: null
        };
        this.isDataFetchInProgress = false
    }

    componentDidMount() {
        this.fetchGuests(getGuestsInvite());
        if (!this.state.isLoadingFinished) {
            this.loaderTimerId = setInterval(() => {
                this.tick()
            }, 2000);
        }
    }

    componentWillUnmount() {
        clearInterval(this.loaderTimerId);
    }

    fetchGuests(invite) {
        const me = this
        if (invite.anon !== undefined) {
            me.setState({isDataFetched: true, anon: true});
            return
        }
        if (this.isDataFetchInProgress || this.state.isDataFetched) return

        this.isDataFetchInProgress = true;

        apiCall(
            "load",
            invite,
            (data) => {
                console.log("data", data);
                if (data && data.success && data.users) {
                    if (data.users.length > 0) {
                        me.setState({isDataFetched: true, guests: data.users, invite: invite});
                    } else {
                        me.setState({isDataFetched: true});
                    }
                } else {
                    let msg = "Unknown"
                    if (data && data.error) {
                        msg = data.error
                    }
                    throw new Error(msg)
                }
            },
            () => {
                me.setState({isDataFetched: true})
            }
        ).finally(() => {
            me.isDataFetchInProgress = false
        })
    }

    tick() {
        if (document.readyState === 'complete') {
            this.setState(
                () => ({
                    isLoadingFinished: true
                }),
                () => {
                    clearInterval(this.loaderTimerId);
                }
            );
        }
    }

    render() {
        let appContent;
        if (this.state.isLoadingFinished && this.state.isDataFetched) {
            appContent = [
                <Poster key={"poster"}/>,
                <Content key={"content"} guests={this.state.guests} invite={this.state.invite} anon={this.state.anon}/>,
                <Footer key={"footer"}/>
            ];
        } else {
            appContent = [<Heart key={"heart"}/>]
        }

        return (
            <div className="App">
                {appContent}
            </div>
        );
    }
}

function getGuestsInvite() {
    try {
        const params = new Proxy(new URLSearchParams(window.location.search), {
            get: (searchParams, prop) => searchParams.get(prop),
        });

        let inviteStr = params.invite;

        if (inviteStr === undefined || inviteStr === "" || inviteStr == null) {
            console.log("invite for anon");
            return {anon: true};
        }
        const guests = JSON.parse(decodeURIComponent(atob(inviteStr)));
        console.log("invites for: ", guests);
        return guests;
    } catch (ignored) {
        return {anon: true};
    }
}

export function Heart(props) {
    return (
        <div className="Heart-loader">
            <div id="heart-big">
                <svg width="150" height="150" viewBox="0 0 200 200">
                    <g transform="translate(100 100)">
                        <path transform="translate(-50 -50)" fill="#F7EEE7"
                              d="M92.71,7.27L92.71,7.27c-9.71-9.69-25.46-9.69-35.18,0L50,14.79l-7.54-7.52C32.75-2.42,17-2.42,7.29,7.27v0 c-9.71,9.69-9.71,25.41,0,35.1L50,85l42.71-42.63C102.43,32.68,102.43,16.96,92.71,7.27z"/>
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            values="1; 1.5; 1.25; 1.5; 1.5; 1;"
                            dur="1s"
                            repeatCount="indefinite"
                            additive="sum">
                        </animateTransform>
                    </g>
                </svg>
            </div>
            <div id="heart-small">
                <svg width="110" height="110" viewBox="0 0 200 200">
                    <g transform="translate(100 100)">
                        <path transform="translate(-50 -50)" fill="#F7EEE7"
                              d="M92.71,7.27L92.71,7.27c-9.71-9.69-25.46-9.69-35.18,0L50,14.79l-7.54-7.52C32.75-2.42,17-2.42,7.29,7.27v0 c-9.71,9.69-9.71,25.41,0,35.1L50,85l42.71-42.63C102.43,32.68,102.43,16.96,92.71,7.27z"/>
                        <animateTransform
                            attributeName="transform"
                            type="scale"
                            values="1; 1.5; 1.25; 1.5; 1.5; 1;"
                            dur="1s"
                            repeatCount="indefinite"
                            additive="sum">
                        </animateTransform>
                    </g>
                </svg>
            </div>
        </div>
    );
}

export const IS_DEV = process.env.NODE_ENV === "development"
export const API_URL = IS_DEV ? "http://localhost:8000" : ""
export const API_RETRY_COUNT = 5
export const API_RETRY_TIMEOUT_MS = 500

export function apiCall(path, data, onSuccess, onFail, retryCount = API_RETRY_COUNT) {
    return fetch(`${API_URL}/api/${path}.php`, {
        method: 'post',
        mode: 'cors',
        cache: 'no-cache',
        credentials: 'same-origin',
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: new URLSearchParams({
            "data": JSON.stringify(data)
        })
    }).then((response) => {
        console.log("response", response);
        if (response.status >= 200 && response.status < 300) {
            return response.json()
        }
        throw new Error(response.statusText)
    }).then((data) => {
        return onSuccess(data)
    }).catch((e) => {
        console.log("data fetch request has failed", e)
        if (retryCount > 0) {
            const retryLeft = retryCount - 1
            console.log(`retrying ${retryLeft} retry left...`)
            setTimeout(() => apiCall(path, data, onSuccess, onFail, retryLeft), API_RETRY_TIMEOUT_MS)
        } else {
            console.log(`all retries have failed, use fallback data`)
            onFail(e)
        }
    })
}

export default App;
