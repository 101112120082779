import React from 'react';
import './AnimatedBlock.css'
import handleViewport from 'react-in-viewport';

class AnimatedBlock extends React.Component {
    constructor(props) {
        super(props);
        this.firstVisit = true;
    }

    getAnimationClass() {
        return this.selectAppearanceBehaviour(
            `before-${this.props.animationType}-animation`,
            `${this.props.animationType}-animation`,
            `${this.props.animationType}-animation`
        );
    }

    getDisplayClass() {
        if (this.props.isInlined === true) {
            return "AnimatedBlock-inlined"
        } else {
            return ""
        }
    }

    selectAppearanceBehaviour(notShown, firstTime, afterFirstTime) {
        const {inViewport, enterCount} = this.props;
        if (inViewport && enterCount === 1) {
            return firstTime;
        } else if (!inViewport && enterCount < 1) {
            return notShown;
        } else {
            return afterFirstTime;
        }
    }

    onFirstView() {
        if (!this.props.onFirstView) return;

        const {inViewport, enterCount} = this.props;
        if (inViewport && enterCount === 1 && typeof (this.props.onFirstView) == 'function') {
            this.props.onFirstView()
        }
    }

    render() {
        const className = ("AnimatedBlock " + this.getAnimationClass() + " " + this.getDisplayClass()).trim();
        this.onFirstView()

        if (this.props.isInlined === true) {
            return (
                <span className={className}>
                    {this.props.children}
                </span>
            );
        } else {
            return (
                <div className={className}>
                    {this.props.children}
                </div>
            );
        }

    }
}

const ViewportBlock = handleViewport(AnimatedBlock);

export default ViewportBlock