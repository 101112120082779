import React from 'react';
import './PresentCode.css'

class PresentCode extends React.Component {
    render() {
        return (
            <div className="Content-block Content-block-text PresentCode">
                <h4 id="present-code-title">Мы рады всем гостям, в особенности тем, кто следует правилам нашего
                    праздника</h4>
                <div className="rule-container">
                    <div className="rule">
                        <div className="rule-icon"><span id="rule-icon-18-plus">18+</span></div>
                        <div className="rule-title"><h4>Без детей</h4></div>
                        <hr/>
                        <p className="rule-text">
                            Свадьба - праздник для взрослых. Банкетная площадка не имеет возможности пригласить
                            аниматора или безопасно расположить ваших детей. Поэтому, позаботьтесь о том, кому Вы
                            сможете оставить ребенка на время проведения свадьбы.
                        </p>
                    </div>
                    <div className="rule rule-not-first">
                        <div className="rule-icon">
                            <img id="rule-no-flower"/>
                        </div>
                        <div className="rule-title"><h4>Без букетов</h4></div>
                        <hr/>
                        <p className="rule-text">
                            Мы любим цветы, но ввиду скорого отъезда на отдых - все цветы пропадут
                            не успев нас порадовать. Если у вас есть стойкое желание подарить букет, свяжитесь с
                            нашим организатором, она поможет вам организоваться для одного общего большого букета.
                        </p>
                    </div>
                    <div className="rule rule-not-first">
                        <div className="rule-icon">
                            <div id="rule-icon-kiss"><s>горько</s></div>
                        </div>
                        <div className="rule-title"><h4>Без "ГОРЬКО!"</h4></div>
                        <hr/>
                        <div className="rule-text">
                            <p>
                                Горячая просьба от молодежнов, у них достаточно стажа в поцелуях.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PresentCode