import React from 'react';
import './Where.css'
import AnimatedBlock from "./AnimatedBlock";

class Where extends React.Component {
    render() {
        return (
            <div className="Content-block">
                <AnimatedBlock
                    id={"where-map"}
                    animationType={"ease-in"}>
                    <div className="Where-map-container">
                        <a href="https://yandex.ru/maps/org/kholidey_park/1019634304/?utm_medium=mapframe&utm_source=maps"
                           className="Where-map-link-1">Холидей Парк</a>
                        <a href="https://yandex.ru/maps/44/izhevsk/category/resort/184106400/?utm_medium=mapframe&utm_source=maps"
                           className="Where-map-link-2">Дом отдыха в Ижевске</a>
                        <iframe className="Where-map-iframe"
                                src="https://yandex.ru/map-widget/v1/-/CCUNQRC1PD"
                                width={560} height={400} frameBorder={0}
                                allowFullScreen={true}/>
                    </div>
                </AnimatedBlock>
            </div>
        );
    }
}

export default Where