import React from 'react';
import './Credits.css'
import AnimatedBlock from "./AnimatedBlock";
import {callConfirmApi, getConfirmationAction, ConfirmAction, updateConfirmAction} from "./Welcome";

class Credits extends React.Component {
    constructor(props) {
        super(props);
        this.confirm = this.confirm.bind(this);
        this.removeBanner = this.removeBanner.bind(this);

        this.state = {
            confirmAction: getConfirmationAction(props.guests),
            displayThanks: false
        }

        this.callInProgress = false
        this.bannerTimer = null
    }

    updateConfirmAction(action) {
        updateConfirmAction(this, action)
    }

    confirm(value = 1) {
        if (this.callInProgress) return

        if (this.bannerTimer) clearTimeout(this.bannerTimer)

        this.callInProgress = true
        callConfirmApi(this, value).then((action) => {
            this.setState({displayThanks: true})
            this.props.onCofirmActionChange(action)
            this.bannerTimer = setTimeout(this.removeBanner, 3000)
        }).finally(() => {
            this.callInProgress = false
        })
    }

    removeBanner() {
        this.setState({displayThanks: false})
    }

    render() {
        let buttonToUse
        switch (this.state.confirmAction) {
            case ConfirmAction.ALLOW_TO_REJECT:
                buttonToUse = (
                    <button className={"Credits-confirm-button Credits-reject"}
                            onClick={() => this.confirm(0)}>Отказаться
                    </button>
                );
                break
            case ConfirmAction.ASK_TO_ACCEPT:
            case ConfirmAction.NONE:
            default:
                buttonToUse = (
                    <button className={"Credits-confirm-button Credits-accept"}
                            onClick={() => this.confirm(1)}>Подтверждаю
                    </button>
                );
        }

        return (
            <div className="Content-block Credits">
                <AnimatedBlock animationType={"appear"}>
                    {this.state.displayThanks ? <ThanksBanner/> : ""}
                    <div id="credits-background">
                        <div className={"Credits-confirm-block"}>
                            <h3>
                                С нетерпением ждем вас на нашей свадьбе и просим дать подтверждение до<br/><strong>22
                                июля</strong>
                            </h3>
                        </div>
                        {
                            this.props.anon ?
                                "" : <div className={"Credits-confirm-button-container"}>
                                    <AnimatedBlock
                                        animationType={"grow-loop"}
                                        isInlined={false}>
                                        {buttonToUse}
                                    </AnimatedBlock>
                                </div>
                        }
                        <div id="arrow-up-container">
                            <AnimatedBlock animationType={"move-up-and-down"}>
                                <h1 id="arrow-up">
                                    &#8593;
                                </h1>
                            </AnimatedBlock>
                        </div>
                    </div>
                </AnimatedBlock>
            </div>
        );
    }
}

export function ThanksBanner(props) {
    return (
        <div className="thanks-banner">
            <h4>Благодарим за ваш ответ</h4>
        </div>
    );
}

export default Credits