import React from 'react';
import './Countdown.css'
import AnimatedBlock from "./AnimatedBlock";

class Countdown extends React.Component {
    constructor(props) {
        super(props);
        const nowDate = new Date().getTime();
        const eventDate = new Date("2022-08-12T16:00:00.000+04:00").getTime();
        this.state = {
            timeInSeconds: Math.abs(Math.floor((eventDate - nowDate) / 1000))
        };
    }

    componentDidMount() {
        this.timerId = setInterval(() => {
            this.tick()
        }, 1000);
    }

    componentWillUnmount() {
        clearInterval(this.timerId);
    }

    tick() {
        this.setState((state) => ({
            timeInSeconds: state.timeInSeconds + 1
        }));
    }

    render() {
        return (
            <div className="Countdown">
                <div className="Countdown-digits-title">Секунд с момента свадьбы</div>
                <AnimatedBlock animationType={"shake"}>
                    <p className="Countdown-digits"><span>{this.state.timeInSeconds}</span></p>
                </AnimatedBlock>
            </div>
        );
    }
}

export default Countdown
