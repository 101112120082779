import React from 'react';
import './When.css'
import {atcb_init} from 'add-to-calendar-button';
import 'add-to-calendar-button/assets/css/atcb.min.css'
import AnimatedBlock from "./AnimatedBlock";
import {PhoneNumber} from "./WhereToStay";
import {apiCall} from "../App";


export let trackSent = false

function When(props) {
    React.useEffect(atcb_init, []);
    const isAnon = props.anon
    const logVisit = function () {
        if (trackSent || isAnon) return

        trackSent = true
        apiCall(
            "track",
            props.invite,
            (data) => {
                console.log("visit has been logged")
            },
            () => {
                console.log("visit hasn't not logged")
            }
        )
    }

    return (
        <div className="Content-block Content-block-text When">
            <h3 className={"question"}> Когда и где </h3>
            <p>
                Торжественная регистрация и праздничный банкет состоятся <strong className="when-date-time">12 Августа 2022 в 15:00 </strong>
                по ижевскому времени или <strong className="when-date-time">14:00</strong> по московскому,
                на территории лесной усадьбы&nbsp;
                <a
                    className="external-link"
                    target="_blank"
                    rel="noreferrer"
                    href="https://hp18.ru/">
                    Holiday Park
                </a>.
            </p>
            <p className="When-details">
                В день свадьбы мы будем немного заняты, поэтому, в случае возникновения вопросов,
                Вы всегда можете обратиться за помощью к нашему свадебному организатору Надежде <PhoneNumber/>
            </p>
            <Calendar/>
            <AnimatedBlock
                animationType={"grow-loop"}
                isInlined={false}
                onFirstView={() => {
                    logVisit()
                }}>
                <div className='atcb' style={{display: 'none'}}>
                    {JSON.stringify({
                        name: "Свадьба Артёма и Тани",
                        description: `Место проведение HolidayPark: https://hp18.ru/`,
                        location: "г. Ижевск ул. Воткинское шоссе 89",
                        label: "Добавить в календарь",
                        startDate: "2022-08-12T16:00",
                        endDate: "2022-08-12T23:00",
                        options: ['Apple', 'Google', 'iCal', 'Microsoft365', 'Outlook.com', 'MicrosoftTeams'],
                        timeZoneOffset: "+04:00",
                        trigger: "click",
                        iCalFileName: "Свадьба Артёма и Тани",
                        inline: true
                    })}
                </div>
            </AnimatedBlock>
        </div>
    );
}

export function Calendar(props) {
    return (
        <div className="Calendar">

        </div>
    );
}


export default When