import React from 'react';
import './WhereToStay.css'

class WhereToStay extends React.Component {
    render() {
        return (
            <div className="Content-block Content-block-text">
                <h3 id="faq-header">F.A.Q.</h3>
                <div className="faq-icon"><img className="faq-icon-img"/></div>
                <div className="faq-container">
                    <div className="faq-item">
                        <h4 className="faq-title">
                            Я приехал из далека, где мне можно разместиться
                        </h4>
                        <hr/>
                        <p className={"justify-text faq-text"}>Гостям, приезжим из далека, мы предлагаем разместиться в
                            уютных
                            домиках
                            прямо на
                            месте проведения свадебного торжества. Для вашего удобства на каждом домике будет
                            размещен список постояльцев, если сомневаетесь или потерялись &#129300; - смело обращайтесь
                            к органзиаторам.
                        </p>
                    </div>

                    <div className="faq-item faq-item-not-first">
                        <h4 className="faq-title">
                            Хочу сделать молодоженам сюрприз или выступить с инициативой, как быть
                        </h4>
                        <hr/>
                        <p className={"justify-text faq-text"}>Прежде чем что-то планировать, обязательно свяжитесь с
                            нашим
                            свадебным организатором Надеждой по номеру&nbsp;
                            <PhoneNumber number={+79836209150} text={"+7 (983) 620-91-50"}/>, она
                            поможет вам организовать всё на высшем уровне. Помните, любая инициатива должна быть
                            сюрпризом для молодоженов, но не для организатора.
                        </p>
                    </div>
                </div>
            </div>
        );
    }
}

export function PhoneNumber(props) {
    const {number, text} = props
    return (
        <span className={"PhoneNumber"}>
            <a href={"tel:" + (number || CONTACT_PHONE)}>{text || CONTACT_PHONE_FORMATTED}</a>
        </span>
    );
}

export default WhereToStay

export const CONTACT_PHONE = "+79836209150"
export const CONTACT_PHONE_FORMATTED = "+7 (983) 620-91-50"