import React from 'react';
import './Poster.css'
import Countdown from "./Countdown";
import AnimatedBlock from "./AnimatedBlock";

class Poster extends React.Component {
    render() {
        return (
            <header>
                <AnimatedBlock animationType={"ease-in"}>
                    <div id="header-background"/>
                    <Countdown/>
                </AnimatedBlock>

                <div id="banner">
                    <AnimatedBlock animationType={"move-up-and-down"}>
                        <h1 id="banner-text">
                            &darr;
                        </h1>
                    </AnimatedBlock>
                </div>

            </header>
        );
    }
}

export default Poster