import React from 'react';
import './DressCode.css'
import AnimatedBlock from "./AnimatedBlock";

class DressCode extends React.Component {

    constructor(props) {
        super(props);
        this.onEnter = this.onEnter.bind(this);
        this.onLeave = this.onLeave.bind(this);
    }

    componentDidMount() {
    }

    onEnter() {
    }

    onLeave() {
    }

    render() {
        const colorItems = colors.map((color) => {
            return <DressCodeColor key={color} color={color}/>;
        });
        return (
            <div className="Content-block DressCode">
                <div className="Content-block-text">
                    <h4>
                        Мы очень старались сделать торжество красивым и гармоничным, поэтому будем признательны, если вы
                        поддержите цветовую палитру нашей свадьбы
                    </h4>
                </div>
                <AnimatedBlock
                    animationType={"slide-in"}
                    onLeaveViewport={() => this.onLeave()}
                    onEnterViewport={() => this.onEnter()}>
                    <div className="DressCode-color-bar">
                        {colorItems}
                    </div>
                    <AnimatedBlock
                        animationType={"chameleon"}>
                        <div className="DressCode-color-semaphore"/>
                    </AnimatedBlock>
                </AnimatedBlock>
                <p className="DressCode-details">
                    Свадьба состоится на открытом воздухе, просим вас воздержаться от тонкого каблука и неудобной обуви.
                </p>
                <hr/>
            </div>
        );
    }
}

export class DressCodeColor extends React.Component {
    render() {
        return (
            <div className="DressCode-color-item" style={{background: this.props.color}}/>
        );
    }
}

const colors = ["#F7EEE7", "#9FA1A0", "#BDAEB0", "#F4D0CA", "#AECAC3", "#658996", "#8C9E75", "#4F7169"]

export default DressCode